import ReactDOM from "react-dom/client";
import { Routes, Route, HashRouter } from "react-router-dom";
import './App.css';
import { useTranslation } from "react-i18next";
import Layout from "./pages/Layout/Layout";
import Home from './pages/Home/Home';
import About from "./pages/About/About";
import PropertyManagement from "./pages/PropertyManagement/PropertyManagement";
import RentalManagement from "./pages/RentalManagement/RentalManagement";
import OurProperties from "./pages/OurProperties/OurProperties";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import LegalNotice from "./pages/LegalNotice/LegalNotice";
import Blog from "./pages/Blog/Blog";
import { popularArticles } from './static/content/BlogContent/PopularArticles';
import { latestArticles } from './static/content/BlogContent/LatestArticles';
import Article1 from "./pages/Blog/Articles/Article1";
import Article2 from "./pages/Blog/Articles/Article2";
import Article3 from "./pages/Blog/Articles/Article3";
import Article4 from "./pages/Blog/Articles/Article4";
import Article5 from "./pages/Blog/Articles/Article5";
import Article6 from "./pages/Blog/Articles/Article6";
import Article7 from "./pages/Blog/Articles/Article7";
import Article8 from "./pages/Blog/Articles/Article8";
import Article9 from "./pages/Blog/Articles/Article9";
import Article10 from "./pages/Blog/Articles/Article10";

export default function App() {
  const { t } = useTranslation("common");
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="property-management" element={<PropertyManagement />} />
          <Route path="rental-management" element={<RentalManagement />} />
          <Route path="our-properties" element={<OurProperties />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="blog" element={<Blog />} />
          <Route path={`blog/${popularArticles.article1.slug}`} element={<Article1 />} />
          <Route path={`blog/${popularArticles.article2.slug}`} element={<Article2 />} />
          <Route path={`blog/${popularArticles.article3.slug}`} element={<Article3 />} />
          <Route path={`blog/${popularArticles.article4.slug}`} element={<Article4 />} />
          <Route path={`blog/${latestArticles.article1.slug}`} element={<Article5 />} />
          <Route path={`blog/${latestArticles.article2.slug}`} element={<Article6 />} />
          <Route path={`blog/${latestArticles.article3.slug}`} element={<Article7 />} />
          <Route path={`blog/${latestArticles.article4.slug}`} element={<Article8 />} />
          <Route path={`blog/${latestArticles.article5.slug}`} element={<Article9 />} />
          <Route path={`blog/${latestArticles.article6.slug}`} element={<Article10 />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="legal-notice" element={<LegalNotice />} />
        </Route>
      </Routes>
      <Footer slogan={t('footer.slogan')}/>
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
