import './Blog.scss';
import hero from "./../../static/images/blog/hero.jpg";
import Hero from "../../components/Hero/Hero";
import { popularArticles } from "../../static/content/BlogContent/PopularArticles";
import { latestArticles } from '../../static/content/BlogContent/LatestArticles';
import BlogCardColumn from "../../components/BlogCardColumn/BlogCardColumn";
import BlogCardRow from "../../components/BlogCardRow/BlogCardRow";

const Blog = () => {
    return (
        <>
            <Hero heroImg={hero} heading1="Premavista Blog" />
            <div className="blog container">
                <h2 style={{marginTop: '60px', marginBottom: '30px'}}>Popular Articles</h2>
                <div className="flex" style={{alignItems: 'flex-start'}}>
                    <div className="blog-col-space flex flex__col--6">
                        <BlogCardColumn
                            slug={popularArticles.article1.slug}
                            imgSrc={popularArticles.article1.imgSrc} 
                            imgAlt={popularArticles.article1.imgAlt} 
                            date={popularArticles.article1.date} 
                            heading3={popularArticles.article1.title}
                            caption={popularArticles.article1.caption}
                        />
                    </div>
                    <div className="flex flex--column flex__col--6" style={{alignItems: 'flex-start'}}>
                        <BlogCardRow
                            slug={popularArticles.article2.slug}
                            imgSrc={popularArticles.article2.imgSrc}
                            imgAlt={popularArticles.article2.imgAlt}
                            date={popularArticles.article2.date}
                            heading3={popularArticles.article2.title}
                            caption={popularArticles.article2.caption}
                            isCaption={true}
                        />
                        <BlogCardRow
                            slug={popularArticles.article3.slug}
                            imgSrc={popularArticles.article3.imgSrc}
                            imgAlt={popularArticles.article3.imgAlt}
                            date={popularArticles.article3.date}
                            heading3={popularArticles.article3.title}
                            caption={popularArticles.article3.caption}
                            isCaption={true}
                        />
                        <BlogCardRow
                            slug={popularArticles.article4.slug}
                            imgSrc={popularArticles.article4.imgSrc}
                            imgAlt={popularArticles.article4.imgAlt}
                            date={popularArticles.article4.date}
                            heading3={popularArticles.article4.title}
                            caption={popularArticles.article4.caption}
                            isCaption={true}
                        />
                    </div>
                </div>
            </div>
            <div className="blog container">
                <h2 className="blog-header2">Latest Articles</h2>
                <div className="flex flex--column">
                    <div className="flex" style={{ alignItems: 'flex-start' }}>
                        <BlogCardColumn
                            slug={latestArticles.article1.slug}
                            imgSrc={latestArticles.article1.imgSrc}
                            imgAlt={latestArticles.article1.imgAlt}
                            date={latestArticles.article1.date}
                            heading3={latestArticles.article1.title}
                            caption={latestArticles.article1.caption}
                            className="blog-col-space flex__col--4"
                        />
                        <BlogCardColumn
                            slug={latestArticles.article2.slug}
                            imgSrc={latestArticles.article2.imgSrc}
                            imgAlt={latestArticles.article2.imgAlt}
                            date={latestArticles.article2.date}
                            heading3={latestArticles.article2.title}
                            caption={latestArticles.article2.caption}
                            className="blog-col-space flex__col--4"
                        />
                        <BlogCardColumn
                            slug={latestArticles.article3.slug}
                            imgSrc={latestArticles.article3.imgSrc}
                            imgAlt={latestArticles.article3.imgAlt}
                            date={latestArticles.article3.date}
                            heading3={latestArticles.article3.title}
                            caption={latestArticles.article3.caption}
                            className="flex__col--4"
                        />
                    </div>
                    <div className="flex" style={{ alignItems: 'flex-start' }}>
                        <BlogCardColumn
                            slug={latestArticles.article4.slug}
                            imgSrc={latestArticles.article4.imgSrc}
                            imgAlt={latestArticles.article4.imgAlt}
                            date={latestArticles.article4.date}
                            heading3={latestArticles.article4.title}
                            caption={latestArticles.article4.caption}
                            className="blog-col-space flex__col--4"
                        />
                        <BlogCardColumn
                            slug={latestArticles.article5.slug}
                            imgSrc={latestArticles.article5.imgSrc}
                            imgAlt={latestArticles.article5.imgAlt}
                            date={latestArticles.article5.date}
                            heading3={latestArticles.article5.title}
                            caption={latestArticles.article5.caption}
                            className="blog-col-space flex__col--4"
                        />
                        <BlogCardColumn
                            slug={latestArticles.article6.slug}
                            imgSrc={latestArticles.article6.imgSrc}
                            imgAlt={latestArticles.article6.imgAlt}
                            date={latestArticles.article6.date}
                            heading3={latestArticles.article6.title}
                            caption={latestArticles.article6.caption}
                            className="flex__col--4"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog;