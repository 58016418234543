import { useTranslation } from "react-i18next";
import villaAlmaImg from './../../images/home/villa-alma.jpg';
import villaBohemeImg from './../../images/home/villa-boheme.jpg';
import villaElementsImg from './../../images/home/villa-elements.jpg';
import villaLigeraImg from './../../images/home/villa-ligera.jpg';
import villaSensesImg from './../../images/home/villa-senses.jpg';
import raesborreDomainImg from './../../images/home/raesborre-domain.jpg';
import urbanizacionJardinesImg from './../../images/home/urbanizacion-jardines-de-don-carlos.jpeg';


const useHomeCarouselProperties = () => {
    const { t } = useTranslation("common");
        const slides = [
        {
            imgSrc: villaAlmaImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-1-linkText'),
            linkHref: 'https://book.hostfully.com/premavista-solutions-s.l./property-details/a6217519-b8e7-4c8d-9034-aadbf9fdf2ee/Villa%20ALMA'
        },
        {
            imgSrc: villaBohemeImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-2-linkText'),
            linkHref: 'https://book.hostfully.com/premavista-solutions-s.l./search'
        },
        {
            imgSrc: villaElementsImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-3-linkText'),
            linkHref: 'https://book.hostfully.com/premavista-solutions-s.l./search'
        },
        {
            imgSrc: villaLigeraImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-4-linkText'),
            linkHref: 'https://book.hostfully.com/premavista-solutions-s.l./search'
        },
        {
            imgSrc: villaSensesImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-5-linkText'),
            linkHref: 'https://book.hostfully.com/premavista-solutions-s.l./search'
        },
        {
            imgSrc: raesborreDomainImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-6-linkText'),
            linkHref: 'https://book.hostfully.com/premavista-solutions-s.l./search'
        },
        {
            imgSrc: urbanizacionJardinesImg,
            imgAlt: t('home.carousel-properties-slide-1-imgAlt'),
            linkText: t('home.carousel-properties-slide-7-linkText'),
            linkHref: 'https://book.hostfully.com/premavista-solutions-s.l./property-details/8fad8cb0-3f2d-4c3f-9376-9fbd954c935e/King%20Bed,%20Beach%20in%20300m,%203Pools,%20Free%20Gym+Parking'
        }
    ]
    
    return slides
}

export default useHomeCarouselProperties;