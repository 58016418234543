import './BlogCardColumn.scss';
import { Link } from "react-router-dom";

const BlogCardColumn = ({ slug, imgSrc, imgAlt, date, heading3, caption, style, className }) => {
    return (
        <div className={`blog-card-col flex flex--column ${className}`} style={style}>
            <img src={imgSrc} alt={imgAlt} className="image"/>
            <p className="date">{date}</p>
            <h3><Link to={`/blog/${slug}`}>{heading3}</Link></h3>
            {caption && <p>{caption}</p>}
        </div>
    )
}

export default BlogCardColumn;