import popularArticle1Img from './../../images/blog/popular/article1.jpg';
import popularArticle2Img from './../../images/blog/popular/6Q5A8782.jpg';
import popularArticle3Img from './../../images/blog/popular/article3.jpg';
import popularArticle4Img from './../../images/blog/popular/karl-muscat-N9lhz4DCr14-unsplash.jpg';

export const popularArticles = {
    article1: {
        slug: 'maximize-marbella-rental-income-expert-tips-2025',
        tag: "Popular Articles",
        imgSrc: popularArticle1Img,
        imgAlt: 'Villas',
        date: 'February 1, 2025',
        title: 'Maximize Your Marbella Rental Income: Expert Tips for Property Owners in 2025',
        caption: 'Maximize your Marbella rental income in 2025 with expert strategies, including high-impact property upgrades, smart pricing, professional photography, exceptional guest experiences, legal compliance, and professional management.',
    },
    article2: {
        slug: 'marbella-short-term-rental-regulations-2025-essential-guide-for-property-owners',
        tag: "Popular Articles",
        imgSrc: popularArticle2Img,
        imgAlt: 'Villas',
        date: 'January 10, 2025',
        title: 'Marbella Short-Term Rental Regulations 2025: Essential Guide for Property Owners',
        caption: 'Stay compliant and profitable in Marbella’s short-term rental market by understanding key 2025 regulations, including registration, occupancy limits, tax obligations, and mandatory guest reporting.'
    },
    article3: {
        slug: 'why-professional-property-management-is-crucial-for-marbella-rental-success-2025',
        tag: "Popular Articles",
        imgSrc: popularArticle3Img,
        imgAlt: 'Villas',
        date: 'December 15, 2024',
        title: 'Why Professional Property Management is Crucial for Marbella Rental Success in 2025',
        caption: 'Professional property management is key to Marbella rental success in 2025, ensuring regulatory compliance, maximizing revenue, enhancing guest experiences, and maintaining your property with expert local support.'
    },
    article4: {
        slug: 'top-marbella-neighborhoods-for-short-term-rentals-visitors-guide-2025',
        tag: "Popular Articles",
        imgSrc: popularArticle4Img,
        imgAlt: 'Sea side walk',
        date: 'January 21, 2025',
        title: "Top Marbella Neighborhoods for Short - Term Rentals: Visitor's Guide 2025",
        caption: 'Discover the best Marbella neighborhoods for short-term rentals in 2025, from the luxury of Puerto Banús to the charm of Old Town, ensuring the perfect stay for every traveler.'
    },
}
