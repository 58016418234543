import latestArticle1Img from './../../images/blog/latest/article1.jpg';
import latestArticle2Img from './../../images/blog/latest/article2.jpg';
import latestArticle3Img from './../../images/blog/latest/article3.jpg';
import latestArticle4Img from './../../images/blog/latest/article4.jpg';
import latestArticle5Img from './../../images/blog/latest/bryan-mancin-3t9Caxz6VOc-unsplash.jpg';
import latestArticle6Img from './../../images/blog/latest/sophia-simoes-fIjnRuaMeXE-unsplash.jpg';

export const latestArticles = {
    article1: {
        slug: 'marbellas-booming-short-term-rental-market-key-trends-2025',
        tag: "Latest Articles",
        imgSrc: latestArticle1Img,
        imgAlt: 'Villas',
        date: 'February 4, 2025',
        title: "Marbella's Booming Short - Term Rental Market: Key Trends for 2025",
        caption: 'Stay ahead in Marbella’s booming short- term rental market by embracing key 2025 trends, including remote work stays, eco- friendly upgrades, experiential travel, smart technology, and evolving regulations.'
    },
    article2: {
        slug: 'preparing-your-property-for-the-high-season-in-marbella-comprehensive-guide',
        tag: "Latest Articles",
        imgSrc: latestArticle2Img,
        imgAlt: 'Villas',
        date: 'February 2, 2025',
        title: 'Preparing Your Property for the High Season in Marbella: A Comprehensive Guide',
        caption: 'Expert tips for preparing a Marbella rental for the high season, including timing, property upgrades, essential amenities, marketing strategies, and regulatory compliance to maximize bookings and guest satisfaction.'
    },
    article3: {
        slug: 'the-impact-of-new-regulations-on-marbellas-rental-market-in-2025',
        tag: "Latest Articles",
        imgSrc: latestArticle3Img,
        imgAlt: 'Villas',
        date: 'January 30, 2025',
        title: "The Impact of New Regulations on Marbella's Rental Market in 2025",
        caption: "Marbella's evolving short-term rental market in 2025 is shaped by new regulations, including licensing requirements, occupancy limits, and sustainability practices, impacting property owners and investment opportunities."
    },
    article4: {
        slug: 'luxury-villas-vs-apartments-which-is-right-for-your-marbella-vacation',
        tag: "Latest Articles",
        imgSrc: latestArticle4Img,
        imgAlt: 'Sea side walk',
        date: 'January 21, 2025',
        title: "Luxury Villas vs. Apartments: Which is Right for Your Marbella Vacation?",
        caption: 'Choosing between a luxury villa and a high-end apartment for your Marbella vacation in 2025 depends on your preferences for privacy, space, location, and amenities.'
    },
    article5: {
        slug: 'sustainability-in-short-term-rentals-how-eco-friendly-practices-attract-guests-in-marbella',
        tag: "Latest Articles",
        imgSrc: latestArticle5Img,
        imgAlt: 'Sea side walk',
        date: 'January 16, 2025',
        title: "Sustainability in Short-Term Rentals: How Eco-Friendly Practices Attract Guests in Marbella",
        caption: 'Eco-friendly practices in Marbella short-term rentals are gaining popularity, offering both environmental benefits and increased appeal to eco-conscious travelers in 2025.'
    },
    article6: {
        slug: 'digital-nomads-and-remote-workers-growing-market-for-short-term-rentals-in-marbella',
        tag: "Latest Articles",
        imgSrc: latestArticle6Img,
        imgAlt: 'Sea side walk',
        date: 'January 7, 2025',
        title: "Digital Nomads and Remote Workers: A Growing Market for Short-Term Rentals in Marbella",
        caption: "The rise of remote work is reshaping Marbella's rental market, offering property owners the opportunity to cater to digital nomads and remote workers by providing work-friendly amenities and extended stay options in 2025."
    },
}