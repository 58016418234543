import './BlogCardRow.scss';
import { Link } from "react-router-dom";

const BlogCardRow = ({ slug, imgSrc, imgAlt, date, heading3, caption, isCaption}) => {
    return (
        <div className="blog-card-row flex">
            <img src={imgSrc} alt={imgAlt} className="image"/>
            <div className="text flex flex--column flex__col--6">
                <p className="date">{date}</p>
                <h3><Link to={`/blog/${slug}`}>{heading3}</Link></h3>
                {caption && <p className={`${isCaption ? "blog-card-caption" : ""}`}>{caption}</p>}
            </div>
        </div>
    )
}

export default BlogCardRow;